// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$white: #ffffff;
$black: #000000;
$blue: #0EABB7;//#00A1D8;
$dark: #0A3432;
$red: #FA6026;
