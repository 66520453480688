// Fonts
@import url('https://fonts.bunny.net/css?family=Roboto');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

//Admin-LTE
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
$fa-font-path:"../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
/*--------------------------------------Common------------------------------------------*/
body{
    font-family: 'Roboto';
}
html {
    //Smooth scroll
    scroll-behavior: smooth;
}
.required:after {
    content:" *";
        color: $red;
}
/*--------------------------------------./Common------------------------------------------*/

/*--------------------------------------HOME------------------------------------------*/
#home{
    .hero{
        min-height: 100vh;
        background-image: linear-gradient(rgba(14, 171, 183, 0.6), rgba(14, 171, 183, 0.6)), url(/images/laptop.jpg);
        background-size: cover;
        background-position: center;
        nav{
            border-top: 1.5px solid $red;
            //Dropdown menu
            .dropdown-menu{
                background-color: transparent;
                border: none;
                box-shadow: none;
                a{
                    margin-top: 5px;
                    background-color: $white;
                    color: $blue;
                    font-size: small;
                    font-weight: bold;
                }
                a:hover{
                    border-left: 2px solid $red;
                }
            }
        }
    }

    #preview{
        color: $black;
    }
}
/*--------------------------------------./HOME------------------------------------------*/


/*-------------------------------------------------App-------------------------------------------------*/
#app {
    min-height: 100vh;
    nav{
        border-top: 1.5px solid $red;
        background-color: $blue;
        .navbar-nav{
            .nav-link{
                color: $white;
            }
            .nav-link:hover{
                font-weight: bold;
            }
        }
    }
    .card{
        a{
            color: $blue;
            text-decoration: none;
        }
        a:hover{
            color: $red;
        }
    }
}
/*-------------------------------------------------./App-------------------------------------------------*/

/*--------------------------------------AdminLTE------------------------------------------*/
#adminLTE{
    .sidebar-dark-primary {
        background-color: $dark !important;
        a{
            text-decoration: none;
        }
    }
    .main-header{
        background: $blue;
        border-top: 2px solid $red;
            a{
                color: $white;
            }
            .dropdown-menu{
                a{
                    color: $dark;
                }
                a:hover{
                    background-color: $white;
                    font-weight: bold;
                    border-bottom: 2px solid $dark;
                }
            }
        }
        .sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
        .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
            background-color: $blue !important;
            color: $white !important;
            border-left: $red 5px solid !important;
        }
        .sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link:hover,
        .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link:hover,
        .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link:visited {
            border-bottom: 2px solid $white;
            background-color: $dark !important;
        }
        .has-treeview{
        .nav-treeview>li>a,.far{
            padding-top: 0;
            padding-bottom: 0;
            // font-size: 14px;
            }
        }
        //content-wrapper
        .content-wrapper{
          background-color: $body-bg;
          .content-header{
              .breadcrumb-item{
                  a{
                      color: $blue;
                      text-decoration: none;
                  }
                  a:hover{
                      color: $red;
                      text-decoration: none;
                  }
              }
          }
          .content{
              .card{
                  box-shadow: none !important;
                  .card-header{
                      border-bottom: 0;
                  }
                  .card-body{
                    // Pagination
                    .page-item.active .page-link {
                      background-color: $blue;
                      border-color: $blue;
                      color: $white;
                    }
                    .page-link:hover,.page-item.active:hover {
                      color: $white !important;
                      background-color: $red !important;
                      border-color: $red !important;
                    }
                    .page-link {
                      color: $blue;
                    }
                    //CkEditor
                    .ck-editor__editable {
                        min-height: 200px !important;
                    }
                  }
              }
          }
        }
}
/*--------------------------------------./AdminLTE------------------------------------------*/
